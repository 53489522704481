import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MovieType from "../types/MovieType";

const API_EP = process.env.REACT_APP_API_EP?.replace(/\/+$/, "");

export default function Movies() {
    console.log("events useParams ->", useParams());
    const [list, setList] = useState([] as MovieType[]);
    useEffect(() => {
        fetch(`${API_EP}/movies`)
            .then(resp => resp.json())
            .then(list => {
                console.log(list);
                setList(list);
            })
    }, []);


    return <table>
        <tbody>
        {list.map(l => {
            console.log(l);
            return <tr key={String(l.id)}>
                <td>
                    <Link to={l.slug}>{l.titleLong}</Link>
                </td>
            </tr>
        })}</tbody>
    </table>
}