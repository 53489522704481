// import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Movies from "./pages/Movies";
import Movie from "./pages/Movie";
import {Container} from "@mui/material";


function App() {
  return (
      <Container maxWidth="lg">
      <BrowserRouter>
        <Routes>
          <Route index element={<Movies />}></Route>
          <Route path="/movies">
            <Route index element={<Movies />}></Route>
            <Route path=":slug" element={<Movie />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </Container>
  );
}

export default App;
