import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import MovieType from "../types/MovieType";

const API_EP = process.env.REACT_APP_API_EP?.replace(/\/+$/, "");

export default function Movie() {
    const {slug} = useParams();
    const [movie, setMovie] = useState({} as MovieType);

    console.log("events useParams ->", useParams());
    useEffect(() => {
        fetch(`${API_EP}/movies/${slug}`)
            .then(resp => resp.json())
            .then(movie => {
                console.log(movie);
                setMovie(movie);
                setBg(movie.imdbCode);
            });
    }, [slug]);
    return <div>{movie.summary}</div>;
}

function setBg(imdb: string) {
    document.body.style.backgroundImage = `url(${(imdb && `/img/${imdb.substring(0, 4)}/${imdb.substring(4, 6)}/${imdb}/background_original.jpg`) || ""})`;
}